

import React, { useEffect, useState } from "react"

import IntroSpecifications from "../../../components/stand/template/introSpecifications"
import Form from "../../../components/forms/contactFormStand"
import { Buttons } from '../../../components/buttons'


import { InView } from 'react-intersection-observer';

import styled from "styled-components"

import ImageGallery from 'react-image-gallery';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-image-gallery/styles/scss/image-gallery.scss";
import font from "../../../fonts/fonts.module.scss"



const ComponentStandSingleCar = ({ bg, pageContext, form, texts, btnText, btnActionFunction, btnClass, btnForm }) => {
  if (!form.title.includes(pageContext.name.toUpperCase())) {
    var toJoin = ' sobre <br/><b>' + pageContext.name + '</b>'
    form.title = form.title + toJoin.toUpperCase()
  }
  const imgsAux = [
    {
      original: pageContext.image?.sourceUrl,
      thumbnail: pageContext.image?.sourceUrl,
    }
  ];

  pageContext.galleryImages.nodes.map((ImageGallery) => (
    imgsAux.push({
      original: ImageGallery.sourceUrl,
      thumbnail: ImageGallery.sourceUrl
    })
  ))
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (inView) {
      if (typeof document !== undefined) {
        const elem = document.getElementById("form");
        elem.classList.add("p-sticky")
      }
    }
    if (!inView) {
      if (typeof document !== undefined) {
        const elem = document.getElementById("form");
        elem.classList.remove("p-sticky")
      }
    }
  }, [inView]);

  return (

    <ComponentStandSingleCarStyled>
      <div className="container">
        <BgSingleCarStyled bg={bg} />
        <div className="main-div">
          <div className="container-name ">
            <svg width="14.234" height="73.359" viewBox="0 0 14.234 73.359">
              <path d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"
                transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />
            </svg>

            <h2>{pageContext.name}</h2>
          </div>

          <ImageGallery items={imgsAux} thumbnailPosition='right' showPlayButton={false} showBullets={false}
            showFullscreenButton={false}
            onErrorImageURL="https://stand.hcsperformance.pt/wp/wp-content/uploads/2023/02/01_home.webp" />

          <div className="grid container-specs p-relative">
            <InView as="div" className="container-intro-specifications-main" threshold={0} onChange={inViewDiv => {
              setInView(inViewDiv)
            }}>
              <hr className="hr-red" />


              <h2 className={font.oL + ' uppercase title-details'}>{texts.details}</h2>

              <IntroSpecifications marca={pageContext.paMarcas} modelo={pageContext.paModelos} mesano={pageContext.paMesAnos}
                motor={pageContext.paMotors} km={pageContext.paKms} combustivel={pageContext.paCombustivels}
                video={pageContext.paVideos} />

              <h2 className={font.oL + ' uppercase title-details'}>{texts.notes}</h2>

              <StyledContent>
                <p dangerouslySetInnerHTML={{ __html: pageContext.content }} className={font.oL}></p>
              </StyledContent>

            </InView>

            <div className="p-sticky">
              <div id="form">
                <Form data={form} btnText={btnForm.btnText} btnActionFunction={btnForm.btnLink} btnClass={btnForm.btnClass} />
              </div>
            </div>
          </div>

          <div className={font.oEL + ' container-main-btn'} >
            <div className={font.oEL + ' container-btn'}>
              <Buttons text={btnText} actionFunction={btnActionFunction} btnClass={btnClass} big />
            </div>
          </div>

          <div style={{ height: '5vh' }}></div>
        </div>
      </div>
    </ComponentStandSingleCarStyled>
  )
}

export default ComponentStandSingleCar


const BgSingleCarStyled = styled.div`
background-repeat: no-repeat;
background-position: top;
background-size: cover;
background-image:  ${(props) => props.bg || ''};
  left: 0;
  right: 0;
  z-index: -99;
  display: block;
  width: 100%;
  height: 100vh;
  filter: opacity(0.3) grayscale(0.5) ;
  position: fixed;
`
const ComponentStandSingleCarStyled = styled.div`
.container{
  position: relative;
  left: 0;
  right: 0;
  z-index: 99;
}
  .main-div{
    padding: 5vw 5vw 5vw 5vw;
  }

  .grid{
    display: grid;
  }

  .two-columns{
    grid-template-columns: 50% 50%;
  }
  .container-specs{
    grid-template-columns: 68vw 21vw;
  }

  .p-relative{
    position: relative;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .title-details{
    margin-left: 1%;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    letter-spacing: 0.1em;
  }

  .container-intro-specifications-main{
      padding-left: 5.5vw;
      padding-right: 2vw;
  }

  .p-sticky{
    position: sticky;
    height: 5vh;
    top: 5vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    width: 21vw;
    padding-top: 3vh;
  }

  .container-name{
    display: flex;
    text-transform: uppercase;
    margin-bottom: 5vh;

    svg{
      position: relative;
      width: 4vh;
      height: 4vh;
      margin-top: 2vh;
      cursor: pointer;
      :not(:first-child){
        margin-top: 3vh
      }
      path{
        fill: #ab2c29 !important;
      }
    }
  }


  .carousel-slider{
    width: 80%;
    margin: auto;
    height: 70vh;
    img{
      height: 70vh;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .carousel-root{
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .carousel .thumb{
    border: 2px solid #333;
    display: block !important;
  }
  .carousel .thumb.selected, .carousel .thumb:hover{
    border: 2px solid #fff;
  }

  .carousel.carousel-slider .control-arrow:hover{
    background: rgba(0,0,0,0) !important;
  }

  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 70vh;
    height: 70vh;
    object-fit: cover;
    width: 85%;
  }

  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(99% - 15vw) !important;
    margin-right: 4vw !important;
  }

  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 11vw !important;
  }

  .image-gallery-thumbnail {
    width: 12vw !important;
  }

  .image-gallery-thumbnail{
    border: 1px solid transparent;
  }

  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover{
    border: 1px solid #ab2c29 !important;
  }

  .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
    margin-top: 2vh !important;
  }

  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    width: 2vw;
    height: auto;
  }

  .image-gallery-left-nav .image-gallery-svg:hover, .image-gallery-right-nav .image-gallery-svg:hover, .image-gallery-icon:hover {
    color: #ab2c29 !important;

  }

  .hr-red{
    margin-top: 10vh;
    margin-bottom: 10vh;
    border: 0;
    height: 1px;
    background-image: linear-gradient(90deg, rgba(171,44,41,1) 0%, rgba(171,44,41,1) 100%);
  }

  .container-main-btn{
    margin-left: 1%;
    padding-left: 5.1vw;
  }
  .container-btn{
    width: 12vw;
  }
`


const StyledContent = styled.div`
  margin-left: 1%;
  p{
    letter-spacing: 2px !important;
    line-height: 1.3 !important;
  }
  div{
    margin-top: 2.5vh;
  }
`