import React, { useState } from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import ComponentStandSingleCar from "../components/stand/template/standSingleCar"
import ComponentStandSingleCarMobile from "../components/stand/template/standSingleCarMobile"
import PopUpMobile from "../components/stand/popUpMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"


const StandSingleCarTemplate = ({ data, pageContext }) => {

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <SEO title={'Stand | ' + pageContext.name} image={pageContext.image?.sourceUrl} />
      <Layout>
        {useBreakpoint().mobile ? (
          <>
            <HeaderMobile data={data.globalJson.header} services={false} />

            <ComponentStandSingleCarMobile bg={`url("${data.standJson.introMobile.background.childImageSharp.fluid.src}")`}
              pageContext={pageContext} form={data.contactosJson.standForm} texts={data.standJson.texts} setIsOpen={setIsOpen} isOpen={isOpen}

              btnText={data.standJson.btnStandSingle.btnText} btnActionFunction={() => {
                typeof window !== `undefined` && window.open(data.standJson.btnStandSingle.btnLink, '_blank')
              }} btnClass={data.standJson.btnStandSingle.btnClass}
              btnForm={data.standJson.btnForm}
            />

            <PopUpMobile form={data.contactosJson.standForm} setIsOpen={setIsOpen} isOpen={isOpen} pageContextName={pageContext.name} btnForm={data.standJson.btnForm} />

            <FooterMobile data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} stand={true} setIsOpen={setIsOpen} isOpen={isOpen} />
          </>
        ) : (
          <>
            <Header data={data.globalJson.header} services={false} stand={true} />

            <div style={{position: 'relative'}}>
              <ComponentStandSingleCar bg={`url("${data.standJson.intro.background.childImageSharp.fluid.src}")`}
                pageContext={pageContext} form={data.contactosJson.standForm} texts={data.standJson.texts}
                btnText={data.standJson.btnStandSingle.btnText} btnActionFunction={() => {
                  typeof window !== `undefined` && window.open(data.standJson.btnStandSingle.btnLink, '_blank')
                }} btnClass={data.standJson.btnStandSingle.btnClass}
                btnForm={data.standJson.btnForm}
              />
            </div>
            <Footer data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} setIsOpen={setIsOpen} isOpen={isOpen} stand={true} />
          </>
        )}

      </Layout>
    </>
  )
}

export default StandSingleCarTemplate

export const Json = graphql`
  query standSingleCarTemplate {
    standJson{
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      texts{
        details
        caracteristics
        notes
      }
      btnStandSingle{
        btnText
        btnLink
        btnClass
      }
      btnForm{
        btnText
        btnLink
        btnClass
      }
    }
    contactosJson {
      standForm {
        title
        name
        phone
        email
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

  }
`
